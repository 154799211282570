
import { defineComponent, ref, onMounted } from "vue";
import { CreateOrEditAgentDto } from "@/shared/service-proxies/service-proxies";
import { useStore } from "vuex";
import useAgents from "@/composables/useAgents";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import AgentForm from "@/components/Forms/AgentForm.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useRoute } from "vue-router";
import router from "@/router";

export default defineComponent({
  components: {
    AgentForm,
  },
  setup() {
    const { updateOrCreateAgent, getAgent, agent } = useAgents();
    const store = useStore();
    const route = useRoute();
    const loaded = ref(false);

    const saving = ref(false);

    const submit = async () => {
      saving.value = true;
      await updateOrCreateAgent(
        agent.value.agent as unknown as CreateOrEditAgentDto
      );
      const error = store.getters.getErrors;
      saving.value = false;
      if (!error) {
        Swal.fire({
          text: "Agent updated successfully",
          icon: "success",
          buttonsStyling: false,
          confirmButtonText: "Ok",
          customClass: {
            confirmButton: "btn fw-bold btn-light-success",
          },
        });
        router.push({ name: "agent" });
      } else {
        Swal.fire({
          text: error,
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Try again!",
          customClass: {
            confirmButton: "btn fw-bold btn-light-danger",
          },
        });
      }
    };

    onMounted(async () => {
      setCurrentPageBreadcrumbs("Edit Agent", ["Agent", "Edit"]);
      await getAgent(route.params.id as unknown as string);
      loaded.value = true;
    });

    return {
      agent,
      saving,
      submit,
      loaded,
    };
  },
});
